import React, { FunctionComponent, useCallback } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { exactDate, exactDateWithSeconds } from "../../services/date"
import { darkTheme, lightTheme } from "../../services/theme"
import { Donation } from "../../types"
import Avatar from "../layout/avatar"
import ChannelSelector from "./selector"
import { navigate } from "gatsby"
import HtmlTooltip from "../widgets/html-tooltip"
import { prettyAmount } from "../../services/number"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAltLines } from "@fortawesome/pro-light-svg-icons"
import DirectMessageDialog from "../dialogs/direct-message-dialog"
import { useModal } from "../../hooks/use-modal"
import { useUser } from "../../hooks/use-user"

export type Props = {
  donation: Donation
}

const CompactPreview: FunctionComponent<Props> = ({ donation }) => {
  const { filters } = useFilters()
  const { setContent } = useModal()
  const { user } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const filterByChannel = useCallback(
    async (channel: string) => {
      //console.log("filterByChannel " + channel)
      navigate("/channel/" + channel)
      //updateFilters({ channel_ids: [channel] })
    },
    [filters]
  )

  const sendMessage = useCallback(() => {
    if (user) {
      let to = donation.meta
      const msg = {
        from: user.id,
        to: to.account,
        text: "",
        text_outbox: "",
      }
      setContent(
        <DirectMessageDialog initMessage={msg} close={() => setContent(null)} />
      )
    }
  }, [user, donation])

  return (
    <div className="element">
      {filters.donation_feed == "Outbox" ? (
        <>
          <Avatar
            image={donation.meta.icon}
            label={donation.meta.title}
            size={50}
          />
          <div className="table" style={{ width: "100%", marginLeft: "5px" }}>
            <div className="table-row">
              <div className="table-cell" style={{}}>
                <div className="selector">
                  <div className="flex"></div>
                </div>
              </div>
              <div className="table-cell"></div>
              <div
                className="table-cell"
                style={{ float: "right", fontSize: "12px" }}
              >
                {donation.meta.currency}&nbsp;
                {prettyAmount(donation.meta.amount)}
              </div>
            </div>
            <div className="table-row" style={{ fontSize: "12px" }}>
              <div
                className="table-cell"
                style={{ color: "grey", paddingTop: "5px" }}
              >
                <strong
                  onClick={() => {
                    filterByChannel(donation.meta.account)
                  }}
                >
                  {donation.meta.handle.length != 0 ? (
                    <>
                      <span>{donation.meta.title}</span>
                      <span className="handle">@{donation.meta.handle}</span>
                    </>
                  ) : (
                    <>{donation.meta.account}</>
                  )}
                </strong>
              </div>
              <div
                className="table-cell"
                style={{ fontSize: "12px", textAlign: "right" }}
              ></div>
              <div
                className="table-cell"
                style={{ float: "right", fontSize: "12px", display: "flex" }}
              >
                <div style={{ margin: "auto", paddingRight: "30px" }}>
                  {exactDateWithSeconds(donation.ts)}
                </div>

                <div
                  className="thumbsUpContainer"
                  onClick={() => sendMessage()}
                >
                  <FontAwesomeIcon
                    className=""
                    icon={faCommentAltLines}
                    size="lg"
                  />
                </div>

                {donation.state == 0 ? (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div style={{ fontSize: "14px", fontWeight: 400 }}>
                          <p>
                            Your donation is waiting to be processed.
                            <br />
                            Should it stay in the queue for longer than 24 hrs,
                            you can query its status at{" "}
                            <a href="#">recipient email</a>
                          </p>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                    interactive
                  >
                    <div className="status queued">queued</div>
                  </HtmlTooltip>
                ) : (
                  <div className="status processed">processed</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Avatar
            image={donation.meta.icon}
            label={donation.meta.title}
            size={50}
          />
          <div className="table" style={{ width: "100%", marginLeft: "5px" }}>
            <div className="table-row">
              <div className="table-cell">
                <strong
                  onClick={() => {
                    filterByChannel(donation.meta.account)
                  }}
                >
                  {donation.meta.handle.length != 0 ? (
                    <>
                      <span>{donation.meta.title}</span>
                      <span className="handle">@{donation.meta.handle}</span>
                    </>
                  ) : (
                    <>{donation.meta.account}</>
                  )}
                </strong>
              </div>

              <div className="table-cell"></div>
              <div
                className="table-cell"
                style={{ float: "right", fontSize: "12px" }}
              >
                {donation.meta.currency}&nbsp;
                {prettyAmount(donation.meta.amount)}
              </div>
            </div>
            <div className="table-row" style={{ fontSize: "12px" }}>
              <div
                className="table-cell"
                style={{ color: "grey", paddingTop: "5px" }}
              >
                <div className="selector">
                  <div className="flex">
                    {donation.meta.channel_publisher &&
                    donation.meta.channel_publisher.length != 0 ? (
                      <>
                        <strong
                          onClick={() => {
                            filterByChannel(donation.meta.channel_id)
                          }}
                        >
                          {donation.meta.channel_title}
                        </strong>
                        <div style={{ margin: "auto", paddingLeft: "5px" }}>
                          on {donation.meta.channel_publisher}
                          {/*                      <ChannelSelector
                                id={donation.publisher}
                                label={donation.publisher}
                                icon={donation.publisher_icon}
                                name={donation.publisher}
                                filterByChannel={filterByChannel}
                            />*/}
                        </div>
                      </>
                    ) : (
                      donation.meta.channel_id &&
                      donation.meta.channel_id.length != 0 && (
                        <strong
                          onClick={() => {
                            filterByChannel(donation.meta.channel_id)
                          }}
                        >
                          {donation.meta.channel_id}
                        </strong>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div
                className="table-cell"
                style={{ fontSize: "12px", textAlign: "right" }}
              ></div>
              <div
                className="table-cell"
                style={{ float: "right", fontSize: "12px", display: "flex" }}
              >
                <div style={{ margin: "auto", paddingRight: "10px" }}>
                  {exactDateWithSeconds(donation.ts)}
                </div>

                <div
                  className="thumbsUpContainer"
                  onClick={() => sendMessage()}
                >
                  <FontAwesomeIcon
                    className=""
                    icon={faCommentAltLines}
                    size="lg"
                  />
                </div>

                {donation.state == 0 ? (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div style={{ fontSize: "14px", fontWeight: 400 }}>
                          <p>
                            Your donation is waiting to be processed.
                            <br />
                            Should it stay in the queue for longer than 24 hrs,
                            you can query its status at{" "}
                            <a href="#">recipient email</a>
                          </p>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                    interactive
                  >
                    <div className="status queued">queued</div>
                  </HtmlTooltip>
                ) : (
                  <div className="status processed">processed</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <style jsx>{`

.thumbsUpContainer {
  color: rgb(129, 129, 129);
  padding-right: 10px;
  margin: auto;
}
.thumbsUpContainer:hover {
  cursor: pointer;
  color: blue;
}

                .handle {
                    color: rgb(101, 119, 134);
                    margin-left: 4px;
                  }
        
                .processed {
                    background-color: rgb(140, 217, 140);
                }
                .queued {
                    background-color: lightgrey;
                }
                .status {
                    padding: 5px;
                    border-radius: 5px;
                    width: 80px;
                    text-align: center;
                }
                .options {
                  font-size: 15px;
                }
                @media (hover: hover) and (pointer: fine) {
                  .options {
                    visibility: hidden;
                  }
                  .element:hover .options {
                    visibility: visible;
                  }
                }

        
                .flex {
                  display: flex;
                }
                .selector {
                  font-size: 12px;
                  user-select: none;
                  min-width: 50px;
                  display: flex;
                  /*float: left;*/
                  justify-content: space-between;
              }
      
                  .padded {
                        padding-top: 5px;
                    }

                  .bottom {
                        justify-content: space-between;
                        align-items: center;
                    }
                    .row {
                        display: flex;
                        min-width: 0;
                      }
                      .row > :global(*:not(:first-child)) {
                        margin-left: 1em;
                      }
                        
                  .on {
                    font-size: 14px;
                    /*font-family: Roboto;*/
  
                  }
                  .top {
                    font-size: 14px;
                    /*display: inline-flex;*/
                    vertical-align: middle;
                    font-family: Roboto;
                    margin-bottom: 5px;
                  }
                  .owner {
                    font-size: 12px;
                    display: inline-flex;
                    vertical-align: middle;
                    color: rgb(96, 96, 96);
                  }
                  .description {
                    padding-top: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                  }
                  .table {
                      display: table;
                      font-family: Roboto;
                      width: 100%;
                  }
                  .table-cell {
                      display: table-cell;
                  }
                  .table-row {
                      display: table-row;
                  }
                  .element {
                      padding: 10px;
                      background-color: ${css.backgroundColor};
                      width: 100%;
                      display: flex;
                  }
                    article {
                        /*padding: 1.5em;*/
                        /*padding-top: 20px;*/
                        /*border-radius: 0.3em;*/
                        background-color: ${css.backgroundColor};
                        /*border: 1px solid ${css.borderColor};*/
                        transition: 200ms background-color, 200ms border-color;
                        position: relative;
                        z-index: 1;
                    }
                  img {
                      position: absolute;
                      top: -9999px;
                      left: -9999px;
                      right: -9999px;
                      bottom: -9999px;
                      margin: auto;
                      opacity: 0;
                  }
                    .interactable {
                        position: relative;
                        z-index: 1;
                    }
                    .label {
                        min-width: 0;
                    }
                    .image {
                        /*width: 40%;
                        position: absolute;
                        left: -33.333333%;
                        top: 1.5em;
                        overflow: hidden;*/
                        border-radius: 0;
                    }
                    header {
                        display: flex;
                        font-size: 0.8em;
                        overflow: hidden;
                    }
                    header > *:not(:first-child) {
                        margin-left: 1.6em;
                    }
                    h1,
                    .description {
                        margin: 0;
                    }
                    h1 {
                        font-size: 22px;
                        font-weight: 500;
                        margin-bottom: 0.5em;
                    }
                    p {
                        margin: 0;
                        font-size: 0.9em;
                        color: ${css.infoColor};
                        transition: 200ms color;
                    }
                    p:not(:first-child)::before {
                        content: "•";
                        margin-left: -0.5em;
                        padding-right: 0.5em;
                    }
                    .link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        font-size: 0;
                    }
                    .featured-comment {
                        transform: translateY(-2em);
                        margin-bottom: -2em;
                        margin-left: 10%;
                        margin-right: 20%;
                    }
                    button {
                        border-radius: 50%;
                        height: 2em;
                        width: 2em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        background-color: transparent;
                        color: inherit;
                        transition: 200ms background-color;
                        z-index: 1;
                    }
                    button:disabled {
                        opacity: 0.5;
                    }
                    button:not(:disabled):hover {
                        background-color: ${css.backgroundColor};
                    }
                    .action-icon {
                        height: 100px;
                        width: 100px;
                        color: red;
                    }
                    .tag {
                      background-color: rgb(105, 105, 105, 0.1);
                      padding:5px;
                      margin-left:2px;
                      margin-right:2px;
                      border-radius: 5px;
                      font-size: 12px;
                      user-select: none;
                    }
                    .tag:hover {
                      background-color: rgb(105, 105, 105, 0.3);
                      cursor: pointer;
                    }
                    .actions {
                      display: table-row;
                      font-size: 14px;
                      color: #111111;
                      font-weight: 400;
                    }
                    .comments_count {
                      vertical-align: middle;
                      display: table-cell;
                      padding-right: 5px;
                    }
                    .comments {
                      display: table-cell;
                    }
                    .like {
                      display: table-cell;
                      vertical-align: middle;
                      margin-left: 15px;
                      font-size: 14px;
                    }
                    .table { 
                      display: table;
                   }
                   .table-row {display: table-row; }
                   .table-cell {display: table-cell;
                    vertical-align: middle; }
                    .comment-bubble {
                      font-size: 16px;
                      padding-left:5px;
                      padding-right:10px;
                    }
                    .amount {
                      display: table-cell;
                      vertical-align: middle;
                      font-size: 14px;
                      padding-right: 5px;
                    }
                    .link {
                      min-width: 50px;
                      display: flex;
                      align-items: center;
                      text-decoration: none;
                  }
                  .link:hover strong {
                      text-decoration: underline;
                  }
                  strong {
                      /*margin-left: 0.5em;*/
                      font-size: 12px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      /*flex: 1;*/
                      /*display: block;*/

                      background-color: rgba(105, 105, 105, 0.05);
                      padding: 5px;
                      /*margin: auto;
                      margin-right: 1.6em;*/
                      border-radius: 5px;
                                
                  }
                  strong:hover {
                    background-color: rgba(105, 105, 105, 0.2);
                    cursor: pointer;
                }
                         }
  
              `}</style>
    </div>
  )
}
export default CompactPreview
