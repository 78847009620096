import { faQuestionSquare } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"
import HtmlTooltip from "../widgets/html-tooltip"
import AcceptDonation from "../dialogs/accept-donation"
import { useModal } from "../../hooks/use-modal"
import { useUser } from "../../hooks/use-user"
import { prettyAmount } from "../../services/number"
import DirectMessageDialog from "../dialogs/direct-message-dialog"

const DonationTypeFilter: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const [currentDonationFeed, setCurrentDonationFeed] = useState("New")
  const { setContent } = useModal()
  const { user } = useUser()

  useEffect(() => {
    updateFilters({ donation_feed: currentDonationFeed })
  }, [currentDonationFeed])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,

    activeColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
  }, [])

  const acceptDonation = useCallback(() => {
    setContent(<AcceptDonation close={() => setContent(null)} />)
  }, [])

  const sendMessage = useCallback(() => {
    if (user) {
      const msg = {
        from: user.id,
        to: "",
        text: "",
        text_outbox: "",
      }
      setContent(
        <DirectMessageDialog initMessage={msg} close={() => setContent(null)} />
      )
    }
  }, [user])

  const hydrationBugWorkaroundKey = firstUpdate.current ? `client` : `server`

  return (
    <div className="accordion">
      <section>
        <header>
          <div style={{ display: "inlineFlex" }}>
            <ul className="filterConrols">
              <li
                className={
                  filters.donation_feed === "New"
                    ? "controlItem selected"
                    : "controlItem"
                }
                onClick={() => updateFilters({ donation_feed: "New" })}
                key={"new" + hydrationBugWorkaroundKey}
              >
                New
              </li>
              <li
                className={
                  filters.donation_feed === "Inbox"
                    ? "controlItem selected"
                    : "controlItem"
                }
                onClick={() => updateFilters({ donation_feed: "Inbox" })}
                key={"inbox" + hydrationBugWorkaroundKey}
              >
                Inbox
              </li>
              <li
                className={
                  filters.donation_feed === "Outbox"
                    ? "controlItem selected"
                    : "controlItem"
                }
                onClick={() => updateFilters({ donation_feed: "Outbox" })}
                key={"outbox" + hydrationBugWorkaroundKey}
              >
                Outbox
              </li>
              <li
                className={
                  filters.donation_feed === "Support"
                    ? "controlItem selected"
                    : "controlItem"
                }
                onClick={() => updateFilters({ donation_feed: "Support" })}
                key={"support" + hydrationBugWorkaroundKey}
              >
                Feedback
              </li>
            </ul>
            <div
              style={{
                display: "table",
                width: "100%",
                /*paddingLeft: "15px",
                paddingRight: "15px",*/
                paddingTop: "10px",
                fontFamily: "Roboto",
                fontSize: "13px",
                color: "rgb(108, 121, 127)",
              }}
            >
              <div
                className="tcell"
                style={{ width: "120px", verticalAlign: "top" }}
              >
                {user && user.balance.credit_line ? (
                  <>
                    <div className="category">
                      My credit line:{"  "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: user.displayed_currency_symbol,
                        }}
                      />
                      {prettyAmount(user.balance.credit_line)}
                    </div>

                    {/*
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              marginBottom: "5px",
                            }}
                          >
                            Monthly recurring donations
                          </div>
                          <div style={{ fontSize: "12px", fontWeight: 400 }}>
                            <div style={{ marginBottom: "5px" }}>
                              Some of your audience may wish to support you and
                              commit to an automatic monthly donation. Just ask
                              them.
                            </div>
                            <div>
                              You will see the pledges scheduled for the next
                              month here.
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      placement="right"
                      interactive
                    >
                      <div className="category">
                        Donations:{"  "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: user.displayed_currency_symbol,
                          }}
                        />
                        {prettyAmount(user.balance.recurring)}
                      </div>
                        </HtmlTooltip>*/}
                  </>
                ) : (
                  <div> </div>
                )}
              </div>
              <div className="tcell">
                {filters.donation_feed === "Inbox" && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          Accept a donation
                        </div>
                        <div style={{ fontSize: "12px", fontWeight: 400 }}>
                          <p>
                            When you receive a donation that was not
                            automatically registered by the network, you can
                            accept it manually.
                          </p>
                          <p>
                            You might also wish to configure{" "}
                            <a href="#">automatic processing</a>.
                          </p>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                    interactive
                  >
                    <ul className="filterConrols" style={{ float: "right" }}>
                      <li
                        className="controlItem"
                        onClick={() => acceptDonation()}
                        key={"accept" + hydrationBugWorkaroundKey}
                        style={{ height: "32px", fontSize: "12px" }}
                      >
                        accept
                      </li>
                    </ul>

                    {/*

                <ul className="filterConrols" style={{ float: "right" }}>
                  <li
                    className="controlItem"
                    onClick={() => acceptDonation()}
                    key={"liked" + hydrationBugWorkaroundKey}
                  >
                    Accept
                  </li>
                </ul>
*/}
                  </HtmlTooltip>
                )}

                {filters.donation_feed === "Support" && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          Report an issue
                        </div>
                        <div style={{ fontSize: "12px", fontWeight: 400 }}>
                          <p>
                            If you experience an issue with your donation
                            processing you can send direct message to donation
                            recipient.
                          </p>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                    interactive
                  >
                    <ul className="filterConrols" style={{ float: "right" }}>
                      <li
                        className="controlItem"
                        onClick={() => sendMessage()}
                        key={"accept" + hydrationBugWorkaroundKey}
                        style={{ height: "32px", fontSize: "12px" }}
                      >
                        new
                      </li>
                    </ul>
                  </HtmlTooltip>
                )}
              </div>
            </div>
          </div>
        </header>
      </section>

      <style jsx>{`
        .category {
          display: flex;
          height: 20px;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
          vertical-align: top;
        }
        header {
          display: block;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          font-family: Roboto;
        }
        h1 {
          margin: 0;
          padding: 0;
          font-size: inherit;
          font-style: inherit;
          font-weight: normal;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: lighter;
        }

        .accordion {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
          font-size: 0.9em;
          user-select: none;
          height: 109px;
        }
        section:not(:first-child) {
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .content {
          padding: 5px;
          border-top: 1px solid ${css.borderColor};
          flex-wrap: wrap;
          display: inline-flex;
        }
        .tag {
          background-color: rgb(105, 105, 105, 0.1);
          padding: 5px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          font-size: 14px;
          word-break: keep-all;
          user-select: none;
        }
        .tag:hover {
          background-color: rgb(105, 105, 105, 0.3);
          cursor: pointer;
        }
        .filterConrols {
          display: inline-flex;
          border: 1px solid #d5dddf;
          border-radius: 20px;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style: none;
          background-color: ${css.inputBackground};
        }

        .controlItem {
          padding: 0 15px;
          position: relative;
          display: block;
          margin: 0;
          height: 100%;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -1px;
          height: 40px;
          border: 2px solid transparent;
          border-radius: 3px;
          color: #6c797f;
          font-weight: 700;
          font-size: 13px;
          font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI", Arial,
            sans-serif;
          text-decoration: none;
          cursor: pointer;
        }
        .periodControlItem {
          width: 25%;
          position: relative;
          display: block;
          margin: 0;
          height: 100%;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -1px;
          height: 32px;
          border: 2px solid transparent;
          border-radius: 3px;
          color: #6c797f;
          font-weight: 700;
          font-size: 12px;
          font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI", Arial,
            sans-serif;
          text-decoration: none;
          cursor: pointer;
        }

        .selected {
          /*border: 1px solid ${css.activeColor};*/
          border-radius: 20px;
          background-color: rgb(251, 230, 162);
        }
        .filterAction {
          font-size: 20px;
        }
        .filterAction:hover {
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default DonationTypeFilter
