import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useLazyLoading } from "../../hooks/use-lazy-loading"
import { useUser } from "../../hooks/use-user"
import { Channel, Donation, Item } from "../../types"
import CompactPreview from "../channel/compact-preview"
import Loader from "../widgets/loader"

type Props = {}

const CompactChannelFeed: FunctionComponent<Props> = ({}) => {
  const [items, setItems] = useState<Donation[]>([])
  const [offsetToken, setOffsetToken] = useState(0)
  const { filters, updateFilters } = useFilters()
  const { user } = useUser()

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage " + offsetToken)

      if (user) {
        let url =
          "/api/v2/user_donations/" +
          user.id +
          "?direction=" +
          (filters.donation_feed == "Inbox"
            ? 1
            : filters.donation_feed == "Recurring"
            ? 2
            : 0) +
          "&count=30&offset=" +
          offsetToken

        //console.log(url)

        const response = await Promise.resolve(
          fetch(url)
            .then(res => res.json())
            .catch(error => {
              console.log("json parsing error occured ", error)
              return null
            })
        )
        //console.log(response)
        if (response?.length) {
          console.log(response)
          let res: Donation[] = []
          for (var i = 0; i < response.length; i++) {
            res.push({
              state: response[i].state,
              ts: response[i].ts,
              meta: JSON.parse(response[i].meta),
            })
          }
          //console.log(res)
          return res
        } else {
          //console.log(response)
          return []
        }
      } else return []
    },
    [user, filters, offsetToken]
  )

  const add = useCallback(
    (newItems: Donation[], offset: number) => {
      console.log(newItems)
      if (offset == 0 && items.length > 0) {
        console.log("clearing items")
        setItems([])
      }
      if (newItems && newItems.length) {
        console.log("setting offset token " + newItems[newItems.length - 1].ts)
        if (newItems.length) setOffsetToken(newItems[newItems.length - 1].ts)
        setItems(items => items.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [items]
  )

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<Donation>(items.length ? items : [], { fetchPage, add })

  /*const getContent = useCallback((items: Channel[]) => {
    const itemsCount = 3
    const content: Channel[] = items
    return content
  }, [])

  const content = useMemo(() => getContent(items), [items.length, filters])*/

  useEffect(() => {
    //console.log("Feed: filters changed " + filters.channel_ids)
    setItems([])
    resetLoader()
  }, [filters])

  return (
    <div>
      <ul>
        {items.map(x => (
          <li key={x.ts}>
            <CompactPreview donation={x} />
          </li>
        ))}
      </ul>
      {/*
      {isLoading ? (
        <div className="loader">
          <Loader loading={isLoading} />
        </div>
      ) : (
        !content.length && !isContentAvailable && <p>Reset filters?</p>
      )}
      */}
      <style jsx>
        {`
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          li:not(:first-child) {
            /*margin-top: 1em;*/
          }
          li {
            margin-bottom: 5px;
          }
          .loader {
            padding: 5em 1em;
            text-align: center;
          }
          span {
            margin-left: 0.5em;
          }
          p {
            margin-top: 100px;
            padding: 20px;
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

export default CompactChannelFeed
