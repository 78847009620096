import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { showToast } from "../../components/status-notification"
import { currencies } from "../../etc/currencies"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { rpc } from "../../services/node"
import { darkTheme, lightTheme } from "../../services/theme"
import Select from "../layout/form/select"
import SubmitButton from "../layout/form/submit-button"

type Props = {
  close: () => void
}

const AcceptDonation: FunctionComponent<Props> = ({ close }) => {
  const { user, setBalance, updateBalance } = useUser()
  const [formComplete, setFormComplete] = useState(false)
  const [donationAmount, setDonationAmount] = useState(0)
  const [address, setAddress] = useState("")

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 0) {
      setDonationAmount("")
      return
    } else {
      let amount = parseFloat(event.currentTarget.value)
      if (amount > 0) {
        setDonationAmount(amount)
      } else {
        setDonationAmount(0)
      }
    }
  }, [])

  const onAddressChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      console.log(event.currentTarget.value)
      setAddress(event.currentTarget.value)
    },
    []
  )

  const [targetCurrency, setTargetCurrency] = useState("USD")
  const onTargetCurrencyChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setTargetCurrency(event.currentTarget.value)
    },
    []
  )
  useEffect(() => {
    if (user && user.balance) setTargetCurrency(user.balance.currency)
  }, [user])

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      /*
      let name = ""
      if (user && user.profile && user.profile.name && user.profile.name.length)
        name = user.profile.name
      else name = user.id
*/
      const receipt = {
        donor: address,
        recipient: user.id,
        channel_id: "",
        alias: "",
        id:
          Math.floor(Date.now()).toString() +
          Math.floor(Math.random() * 10000).toString(),
        address: "manual",
        processor: "manual",
        amount: donationAmount,
        currency: targetCurrency,
        target_currency: targetCurrency,
        ts: Math.floor(Date.now() / 1000),
      }

      rpc(
        "confirm_donation",
        JSON.stringify(receipt),
        user,
        getCachedSecret(),
        function (res) {
          let amount = 1
          let message
          if (res.status == "ok") message = "Receipt sent"
          else message = "Receipt failed"

          if (user) updateBalance(-amount)
          showToast(faInfoCircle, "Smartlike network", message)
        }
      )
      close()
    },
    [user, donationAmount, address, targetCurrency]
  )

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <h1>Accept donation</h1>

      <form onSubmit={onSubmit}>
        <div style={{ marginBottom: "10px", lineHeight: "18px" }}></div>

        <div className="component">
          <div style={{ width: "100%", marginBottom: "10px", height: "37px" }}>
            <input
              id="address"
              type="text"
              value={address}
              onChange={onAddressChange}
              className="address"
              placeholder="Donor account public key..."
            />
          </div>

          <div style={{ display: "table-row", width: "100%" }}>
            <div
              className="amountCell"
              style={{ width: "70%", paddingLeft: 0 }}
            >
              <div style={{ display: "flex" }}>
                <input
                  id="pledge"
                  type="number"
                  step="0.01"
                  value={donationAmount}
                  onChange={onAmountChange}
                  className="amount"
                  style={{ width: "150px" }}
                />
                <Select
                  value={targetCurrency}
                  onChange={onTargetCurrencyChange}
                  style={{ width: "100%", marginLeft: "5px" }}
                >
                  {currencies.map(c => (
                    <option key={c.c} value={c.c}>
                      {c.c} - {c.n}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div
              className="amountCell"
              style={{ width: "30%", textAlign: "right" }}
            >
              <SubmitButton>Submit</SubmitButton>
            </div>
          </div>
        </div>
      </form>

      <style jsx>{`
        .inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
        }
        .close:hover {
          cursor: pointer;
        }
        .highlighted {
          font-weight: 400;
          font-size: 14px;
        }

        .amountCell {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          width: 80px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .address {
          width: 100%;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .address:focus,
        .address:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
      `}</style>
    </section>
  )
}

export default AcceptDonation
