import { faFilter } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import Select from "react-select"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"
import { currencies_inc_any } from "../../etc/currencies"
import { countries } from "countries-list"

type Props = {
  showHeader: string
}

const DonationFilter: FunctionComponent<Props> = ({ showHeader }) => {
  const { filters, updateFilters } = useFilters()
  const [processor, setProcessor] = useState({
    value: filters.donation_processor,
    label: filters.donation_processor,
  })
  const [currency, setCurrency] = useState({
    value: filters.donation_currency,
    label: filters.donation_currency,
  })
  const [country, setCountry] = useState({
    value: filters.donation_country,
    label: filters.donation_country,
  })

  useEffect(() => {
    updateFilters({ donation_processor: processor.value })
  }, [processor])

  useEffect(() => {
    updateFilters({ donation_currency: currency.value })
  }, [currency])

  useEffect(() => {
    updateFilters({ donation_country: country.value })
  }, [country])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const paymentProcessors = [
    { value: "any", label: "any" },
    { value: "PayPal", label: "PayPal" },
  ]

  const loadCurrencies = () => {
    let c = []
    for (var i = 0; i < currencies_inc_any.length; i++) {
      c.push({
        label: currencies_inc_any[i].c,
        value: currencies_inc_any[i].c,
      })
    }
    return c
  }

  const paymentCurrencies = loadCurrencies()

  const paymentCountries = useMemo(() => {
    let c = Object.entries(countries)
      .map(([k, v]) => ({
        label: v.name,
        value: k,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    c.unshift({ label: "any", value: "any" })
    return c
  }, [])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,

      border: state.isFocused
        ? "1px solid " + css.focusColor
        : "1px solid " + css.borderColor,

      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? css.focusColor : css.focusColor,
      },
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
      hyphens: "auto",
      // kill the gap
      marginTop: 0,
      textAlign: "left",
      // prevent menu to scroll y
      //wordWrap: "break-word",

      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  }
  return (
    <div className={showHeader === "yes" ? "accordion" : "embedded"}>
      {showHeader === "yes" && (
        <section>
          <header>
            <h1>
              <FontAwesomeIcon icon={faFilter} size="lg" />
              <span>filter recipients</span>
            </h1>
          </header>
        </section>
      )}
      <section>
        <div className="content">
          <div className="filter">
            <div className="filter-name">Processor</div>
            <Select
              defaultValue={processor}
              onChange={setProcessor}
              options={paymentProcessors}
              styles={customStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="filter">
            <div className="filter-name">Currency</div>
            <Select
              defaultValue={currency}
              onChange={setCurrency}
              options={paymentCurrencies}
              styles={customStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="filter">
            <div className="filter-name">Country</div>

            <Select
              defaultValue={country}
              onChange={setCountry}
              options={paymentCountries}
              styles={customStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
      </section>
      <style jsx>{`
        .filter-name {
          margin-bottom: 5px;
          font-size: 12px;
          opacity: 0.4;
        }
        .selector {
          color: red;
        }
        header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          font-family: Roboto;
        }
        header > :global(:not(:first-child)) {
          margin-left: 1em;
        }
        h1 {
          margin: 0;
          padding: 0;
          font-size: inherit;
          font-style: inherit;
          font-weight: normal;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: lighter;
        }
        h1 > *:not(:first-child) {
          margin-left: 1em;
        }

        .accordion {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
          font-size: 0.9em;
          user-select: none;
        }
        .embedded {
          background-color: ${css.backgroundColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
          font-size: 0.9em;
          user-select: none;
        }
        section:not(:first-child) {
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .tag {
          background-color: rgb(105, 105, 105, 0.1);
          padding: 5px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          font-size: 14px;
          word-break: keep-all;
          user-select: none;
        }
        .tag:hover {
          background-color: rgb(105, 105, 105, 0.3);
          cursor: pointer;
        }
        .filterConrols {
          flex-wrap: wrap;
          display: inline-flex;
        }
        .filterAction {
          font-size: 20px;
        }
        .filterAction:hover {
          cursor: pointer;
        }
        .content {
          padding: 5px;
          display: table;
          width: 100%;
          font-family: "Roboto";
        }

        .filter {
          padding: 5px;
          font-size: 14px;
          display: table-cell;
        }
      `}</style>
    </div>
  )
}

export default DonationFilter
